<template>
    <v-container fluid>
        <v-row v-if="get_client.get_user_info.get_survey != null">
            <v-col v-for="(item, index) in wishes[wishes_index].data" :key="index" :cols="isMobile() ? 12 : 6">
                <v-card class="mb-5" elevation="0">
                    <v-card-title style="white-space:pre-line;word-break:break-word;display:inline-block">
                        <h4>{{ item.question }}</h4>
                        <v-spacer />
                    </v-card-title>
                    <v-card-subtitle>
                        <i
                            v-if="(get_client.get_user_info.get_survey.data[wishes[wishes_index].name] != null && get_client.get_user_info.get_survey.data[wishes[wishes_index].name][item.answer] == null) || get_client.get_user_info.get_survey.data[wishes[wishes_index].name][item.answer].length == 0">no
                            answer yet</i>
                        <label v-else-if="get_client.get_user_info.get_survey.data[wishes[wishes_index].name] != null">
                            <v-chip style="height:auto">
                                <strong style="white-space:pre-line;word-break: break-word;"
                                    v-if="item.answer == 'check_box'">
                                    <div v-for="(checkbox_item, checkbox_index) in get_client.get_user_info.get_survey.data[wishes[wishes_index].name][item.answer]"
                                        :key="checkbox_index">
                                        - {{ checkbox_item }}
                                    </div>
                                </strong>
                                <strong style="white-space:pre-line;word-break: break-word;" v-else>{{
                                    get_client.get_user_info.get_survey.data[wishes[wishes_index].name][item.answer]
                                }}</strong>
                            </v-chip>
                        </label>
                    </v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-card elevation="0" width="100%">
                <v-card-title>
                    <small>Client has not answered yet</small>
                </v-card-title>
            </v-card>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
        'wishes',
        'wishes_index'
    ],
    data: () => ({
    }),
    async mounted() {
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_client: 'fd_client/get_client'
        })
    },
    methods: {
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
    }
}
</script>
  
<style scoped lang="scss"></style>