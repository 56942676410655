import MyClients from '../ClientComponent.vue'
import ClientProfile from '../ClientProfile.vue'

export default [
    {
        path: '/my-clients',
        name: '/my-clients',
        component: MyClients,
        meta: {
            layout: 'funeral-director-layout'
        }
    },
    {
        path: '/client-profile',
        name: '/client-profile',
        component: ClientProfile,
        meta: {
            layout: 'funeral-director-layout'
        }
    }
]