<template>
  <v-container fluid>
    <v-expansion-panels accordion>
      <v-expansion-panel v-for="(item, i) in expansion_items" :key="i" @click="expansion_slot(i)">
        <v-expansion-panel-header :id="'panel' + i">
          <v-list-item two-line>
            <v-avatar style="position: relative; right: 18px;" size="100" v-if="!isMobile()">
              <v-img max-width="75" max-height="75" :src="item.icon" />
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <h2 style="white-space:pre-line;word-break:break-word;">{{ item.label }}</h2>
              </v-list-item-title>
              <v-list-item-subtitle style="font-size: 12px;white-space:pre-line;word-break:break-word;" class="mt-2">
                {{ item.sub_label }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div>
            <SurveyWishes :wishes="wishes_details" :wishes_index="panel" />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>
  
<script>
//import InnerFuneral from './inner/InnerFuneral.vue'
import SurveyWishes from './inner/SurveyWishes.vue'
export default {
  components: {
    //InnerFuneral,
    SurveyWishes
  },
  props: [
  ],
  data: () => ({
    panel: null,
    expansion_items: [
      {
        label: 'Burial / Cremation Wishes',
        sub_label: 'What to do with the client\'s body',
        icon: require('../../../assets/activity_farewell_dark_2.svg')
      },
      {
        label: 'Ceremony Wishes',
        sub_label: 'What kind of ceremony the client wants',
        icon: require('../../../assets/activity_post_loss_dark.svg')
      },
      {
        label: 'Wake / Viewing / Reposing Wishes',
        sub_label: 'What the client would like at their wake / viewing / reposing',
        icon: require('../../../assets/activity_memorial_dark.svg')
      },
      {
        label: 'Personal Wishes',
        sub_label: 'Things the client wants or does not want at their funeral',
        icon: require('../../../assets/farewell_health.svg')
      },
      {
        label: 'Funeral Cost',
        sub_label: 'If the client would like an estimate for the funeral cost',
        icon: require('../../../assets/farewell_funeral.svg')
      },
      {
        label: 'Payment Information',
        sub_label: 'If the client would like more information about how to pay',
        icon: require('../../../assets/farewell_digital.svg')
      },
    ],
    
    wishes_details: [
      {
        name: 'funeral_service',
        data: [
          {
            question: 'Burial or cremation?',
            answer: 'label',
            is_active: false,
            is_editable: true
          },
          {
            question: 'Instructions for cremated remains',
            answer: 'check_box',
            is_active: false,
            is_editable: true
          },
          {
            question: 'Preferred location?',
            answer: 'answer',
            is_active: false,
            is_editable: true
          },
          {
            question: 'More details',
            answer: 'details',
            is_active: false,
            is_editable: true
          },
        ]
      },
      {
        name: 'ceremony',
        data: [
          {
            question: 'Would you like your ceremony to be...',
            answer: 'label',
            is_active: false,
            is_editable: true
          },
          {
            question: 'What kind of ceremony would you prefer?',
            answer: 'chosen',
            is_active: false,
            is_editable: true
          },
          {
            question: 'More details',
            answer: 'details',
            is_active: false,
            is_editable: true
          },
        ]
      },
      {
        name: 'wake',
        data: [
          {
            question: 'Would you like to have a wake / public reposing?',
            answer: 'label',
            is_active: false,
            is_editable: true
          },
          {
            question: 'Family home or funeral home?',
            answer: 'choice',
            is_active: false,
            is_editable: true
          },
          {
            question: 'Location',
            answer: 'answer',
            is_active: false,
            is_editable: true
          },
          {
            question: 'Coffin',
            answer: 'coffin',
            is_active: false,
            is_editable: true
          },
          {
            question: 'More details',
            answer: 'details',
            is_active: false,
            is_editable: true
          },
        ]
      },
      {
        name: 'personal_wishes',
        data: [
          {
            question: 'Things that I want',
            answer: 'want',
            is_active: false,
            is_editable: true
          },
          {
            question: "Things that I don't want",
            answer: 'dontwant',
            is_active: false,
            is_editable: true
          },
          {
            question: "People to inform / invite",
            answer: 'people',
            is_active: false,
            is_editable: true
          },
          {
            question: "Details and speakers for eulogy",
            answer: 'eulogy',
            is_active: false,
            is_editable: true
          },
          {
            question: "Symbols of the life lived for a ceremony",
            answer: 'symbols',
            is_active: false,
            is_editable: true
          },
          {
            question: "People to carry the coffin",
            answer: 'coffin_carry',
            is_active: false,
            is_editable: true
          },
          {
            question: "After funeral requests - food / drinks reception",
            answer: 'reception',
            is_active: false,
            is_editable: true
          },
          {
            question: "How to look after ashes",
            answer: 'ashes',
            is_active: false,
            is_editable: true
          },
        ]
      },
      {
        name: 'funeral_cost',
        data: [
          {
            question: 'Would you like to get an estimate for the cost of your funeral?',
            answer: 'label',
            is_active: false,
            is_editable: true
          },
        ]
      },
      {
        name: 'payment_preference',
        data: [
          {
            question: 'Would you like more information about how to pay towards your funeral?',
            answer: 'label',
            is_active: false,
            is_editable: true
          },
        ]
      },
    ],
  }),
  mounted() {
  },
  created() {
  },
  computed: {
  },
  methods: {
    expansion_slot(index) {
      this.panel = index
      setTimeout(() => {
        this.$vuetify.goTo('#panel' + index, { offset: 50 });
      }, 300)
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
  }
}
</script>
  
<style scoped lang="scss"></style>