import ViewInvoice from '../ViewInvoice.vue'

export default [
    {
        path: '/view_invoice/:token',
        name: 'View Invoice',
        component: ViewInvoice,
        meta: {
            layout: 'public-layout'
        }
    },
]