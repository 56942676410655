import Vue from 'vue'

export default{
    namespaced: true,
    state: {
        status: [],
        clients: [],
        client: {},
        gender: {},
        marital_status: {},
        has_special_child: {},
        children: [],
        pets: {},
        beneficiaries: [],
        residual_state: [],
        digital_representative: [],
        digital_personal_representative: [],
        client_funeral: [],
        profile_sharing: [],
        payment_terms: [],
    },
    actions: {
        async get_status({commit}){
            await Vue.axios.get('/auth/status/get_status')
            .then(({data}) => {
                commit('set_status', data.data)
            })
        },
        async get_clients({commit}){
            await Vue.axios.get('/auth/fd_client/get_clients')
            .then(({data}) => {
                commit('set_clients', data.data)
            })
        },
        set_client({commit}, payload){
            commit('set_client', payload)
        },
        async get_gender({commit}, payload){
            await Vue.axios.get('/auth/info/get_gender', {id: payload})
            .then(({data}) => {
                // console.log(commit, data)
                commit('set_gender', data.data)
            })
        },
        async get_marital_status({commit}, payload){
            await Vue.axios.get('/auth/info/get_marital_status', {
                id: payload
            })
            .then(({data}) => {
                commit('set_marital_status', data.data)
            })
        },
        async get_special_children({commit}, payload){
            await Vue.axios.get('/auth/info/has_special_children', {
                id: payload
            })
            .then(({data}) => {
                commit('set_has_special_child', data.data)
            })
        },
        async get_children({commit}, payload){
            await Vue.axios.get('/auth/info/get_children', {
                id: payload
            })
            .then(({data}) => {
                commit('set_children', data.data)
            })
        },
        async get_pets({commit}, payload){
            await Vue.axios.get('/auth/info/get_pets', {
                id: payload
            })
            .then(({data}) => {
                commit('set_pets', data.data)
            })
        },
        async get_beneficiaries({commit}, payload){
            await Vue.axios.get('/auth/info/get_beneficiaries', {
                id: payload
            })
            .then(({data}) => {
                commit('set_beneficiaries', data.data)
            })
        },
        async get_residual_state({commit}, payload){
            await Vue.axios.get('/auth/info/get_residual_state', {
                id: payload
            })
            .then(({data}) => {
                commit('set_residual_state', data.data)
            })
        },
        async get_digital_representative({commit}, payload){
            await Vue.axios.get('/auth/info/get_digital_representative', {
                id: payload
            })
            .then(({data}) => {
                commit('set_digital_representative', data.data)
            })
        },
        async get_digital_personal_representative({commit}, payload){
            await Vue.axios.get('/auth/info/get_digital_personal_representative', {
                id: payload
            })
            .then(({data}) => {
                commit('set_digital_personal_representative', data.data)
            })
        },
        async fetch_client_funeral({commit}, payload){
            await Vue.axios.get(`/auth/client/client_funeral/${payload}`)
            .then(({data}) => {
                // console.log(commit, data)
                commit('set_client_funeral', data.data)
            })
        },
        async fetch_profile_sharing({commit}, payload){
            await Vue.axios.get(`/auth/client/profile_share/get_invited/${payload}`)
            .then(({data}) => {
                commit('set_profile_sharing', data.data)
            })
        },
        async fetch_payment_terms({ commit }) {
            await Vue.axios.get("auth/fd_client/get_payment_terms")
                .then(({ data }) => {
                    commit("set_payment_terms", data.data);
                });
        },
    },
    mutations: {
        set_status(state, payload){
            state.status = [...payload]
        },
        set_clients(state, payload){
            state.clients = [...payload]
        },
        set_client(state, payload){
            state.client = payload
        },
        set_gender(state, payload){
            state.gender = payload
        },
        set_marital_status(state, payload){
            state.marital_status = payload
        },
        set_has_special_child(state, payload){
            state.has_special_child = payload
        },
        set_children(state, payload){
            state.children = [...payload]
        },
        set_pets(state, payload){
            state.set_pets = payload
        },
        set_beneficiaries(state, payload){
            state.beneficiaries = [...payload]
        },
        set_residual_state(state, payload){
            state.residual_state = [...payload]
        },
        set_digital_representative(state, payload){
            state.digital_representative = [...payload]
        },
        set_digital_personal_representative(state, payload){
            state.digital_personal_representative = [...payload]
        },
        set_client_funeral(state, payload){
            state.client_funeral = [...payload]
        },
        set_profile_sharing(state, payload){
            state.profile_sharing = [...payload]
        },
        set_payment_terms(state, payload) {
            state.payment_terms = [...payload];
        },
    },
    getters: {
        get_status : state => state.status,
        get_clients: state => (search, filter) => {
            if (filter !== null) {
                return state.clients.filter(q => {
                    if (search !== null && q.get_user_info != null) {
                        return q.get_status.id == filter && q.get_user_info.name.toLowerCase().includes(search)
                    }
                    return q.get_status.id == filter
                })
            }
            else if (search !== null) {
                return state.clients.filter(q => {
                    if (q.get_user_info != null) {
                        return q.get_user_info.name.toLowerCase().includes(search)
                    }
                })
            }
            else {
                return state.clients
            }
        },
        get_client : state => state.client,
        get_gender : state => state.gender,
        get_marital_status : state => state.marital_status,
        get_has_special_child : state => state.has_special_child,
        get_children : state => state.children,
        get_pets : state => state.pets,
        get_beneficiaries : state => state.beneficiaries,
        get_residual_state : state => state.residual_state,
        get_digital_representative : state => state.digital_representative,
        get_digital_personal_representative : state => state.digital_personal_representative,
        get_client_funeral : state => state.client_funeral,
        get_profile_sharing: state => state.profile_sharing,
        get_payment_terms: (state) => state.payment_terms,
    }
}