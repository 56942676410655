<template>
    <v-container fluid>
        <v-row justify="center">
            <v-dialog :value="edit_profile" persistent max-width="560">
                <v-card>
                    <v-card-title>
                        <small>Edit profile</small>
                    </v-card-title>
                    <v-card-text>
                        <v-row class="mt-5">
                            <v-col :cols="isMobile() ? 12 : 6">
                                <strong> First Name*</strong>
                                <v-text-field dense filled placeholder="First Name" v-model="to_edit.firstname">
                                </v-text-field>
                                
                                <strong> Last Name*</strong>
                                <v-text-field dense filled placeholder="Last Name" v-model="to_edit.lastname">
                                </v-text-field>
                                
                                <strong> Mobile*</strong>
                                <v-text-field dense filled placeholder="Mobile" type="number" v-model="to_edit.mobile">
                                </v-text-field>
                                
                                <strong> Company Name*</strong>
                                <v-text-field dense filled placeholder="Company Name" v-model="to_edit.company_name">
                                </v-text-field>
                                
                                <strong> Bank Details</strong>
                                <v-text-field dense filled placeholder="Bank Details" v-model="to_edit.bank_details">
                                </v-text-field>
                                
                                <strong> Company Logo</strong>
                                <v-file-input
                                    dense
                                    filled
                                    truncate-length="15"
                                    placeholder="Company Logo"
                                    v-model="to_edit.company_logo"
                                    @change="edit_company_logo()"
                                ></v-file-input>
                                
                                <div v-if="getuser.company_logo">
                                    <v-btn class="mb-2" text @click="remove_company_logo()">
                                        <v-icon>mdi-close</v-icon>
                                        Remove logo
                                    </v-btn>
                                    <v-img
                                        :src="`${api_url}frontend/images/${getuser.company_logo}`"
                                        contain
                                        max-height="150"
                                        max-width="150"
                                        class="px-0"
                                    />
                                </div>
                            </v-col>
                            <v-col :cols="isMobile() ? 12 : 6">
                                <strong>Color scheme</strong>
                                <v-color-picker v-model="to_edit.color"></v-color-picker>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="cancel_edit_client()">
                            Cancel
                        </v-btn>
                        <v-btn color="#172C54" dark class="pl-10 pr-10" @click="edit_funeral_director()">
                            Edit
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>
<script>
export default {
    components: {},
    props: [
        'edit_profile',
        'getuser',
    ],
    data: () => ({
        api_url: null,
        edit_client_state: false,
        to_edit: {
            firstname: '',
            lastname: '',
            mobile: '',
            company_name: '',
            company_logo: null,
            color: '',
            bank_details: ''
        },
    }),
    async mounted() {
        this.to_edit = {
            id: this.getuser.id,
            firstname: this.getuser.firstname,
            lastname: this.getuser.lastname,
            mobile: this.getuser.mobile,
            company_name: this.getuser.company_name,
            color: this.getuser.color,
            bank_details: this.getuser.bank_details
        }
    },
    created() { 
        this.api_url = process.env.VUE_APP_API_URL
    },
    computed: {
        
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit(
                "auth/setMessage",
                { show: true, message: message },
                { root: 1 }
            );
        },
        cancel_edit_client() {
            this.$emit('close_edit_profile')
            this.to_edit.company_logo = null
        },
        async edit_company_logo() {
            if(this.to_edit.company_logo != null){
                const formData = new FormData();
                formData.append('id', this.to_edit.id)
                formData.append('company_logo', this.to_edit.company_logo)
                await this.$axios.post("/auth/fd_dashboard/edit_company_logo", formData)
                    .then(({ data }) => {
                        this.showSnackBar(data.message)
                        if (data.response) {
                            this.$store.dispatch("auth/set_user", data.data[0])
                        }
                    })
            }
        },
        async remove_company_logo() {
            await this.$axios.patch("/auth/fd_dashboard/remove_company_logo", { id: this.to_edit.id })
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                    if (data.response) {
                        this.$store.dispatch("auth/set_user", data.data[0])
                    }
                })
        },
        async edit_funeral_director() {
            let payload = {
                id: this.to_edit.id,
                firstname: this.to_edit.firstname,
                lastname: this.to_edit.lastname,
                mobile: this.to_edit.mobile,
                company_name: this.to_edit.company_name,
                color: this.to_edit.color,
                bank_details: this.to_edit.bank_details
            }
            await this.$axios.patch("/auth/fd_dashboard/edit_fd", payload)
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                    if (data.response) {
                        this.$emit('close_edit_profile')
                        this.$store.dispatch("auth/set_user", data.data[0])
                    }
                })
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {},
};
</script>

<style scoped lang="scss">
</style>
