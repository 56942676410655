<template>
    <v-container fluid>
        <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
        <v-row v-else justify="center" align="center">
            <v-card width="90%" class="mt-5" elevation="0">
                <v-card-title class="text-h5" style="background-color: #f4f4f5 !important">
                    <strong style="font-size:26px;">Email Management</strong>
                    <v-spacer />
                </v-card-title>
                <v-card-subtitle class="text-small pb-0" style="background-color: #f4f4f5 !important;height:70px">
                    Manage emails sent to clients.
                </v-card-subtitle>
            </v-card>
            <v-card v-if="sequence.name" width="90%" style="border-radius: 16px" class="mb-10" elevation="0">
                <v-card-title>
                    {{ sequence.name }}
                </v-card-title>
                <v-card-title class="pl-15">
                    <v-select :items="clients" multiple item-text="get_user_info.name" item-value="client_id"
                        v-model="sequence.client_ids" label="Select clients to add to sequence..."
                        style="max-width:50%!important;"></v-select>
                    <v-btn @click="addClientsToSequence()" color="primary" class="ml-5">Save</v-btn>
                </v-card-title>
                <v-card-text class="pl-15">
                    <v-data-table :items="sequence.get_emails" :headers="email_headers" hide-default-footer
                        disable-pagination>
                        <template v-slot:item="{ item }">
                            <tr>
                                <!-- <td align="center">
                                    <b>{{ (item.client_ids) ? item.client_ids.length : 0 }}</b>
                                    <v-btn v-if="item.client_ids && item.client_ids.length > 0" small text
                                        @click="openClientList(item)">View</v-btn>
                                </td> -->
                                <td>
                                    <small>
                                        <div class="mb-5"><b>Subject:</b> {{ item.subject }}</div>
                                        <div class="mb-5"><b>Time:</b> {{ item.time }}</div>
                                        <div class="mb-5">Sent after {{ item.interval }} day<span
                                                v-if="item.interval != 1">s</span> after user is created</div>
                                    </small>
                                </td>
                                <td>
                                    <div style="overflow-y:scroll;max-height:350px;padding:20px;" v-html="item.content">
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template slot="no-data">
                            No emails found in sequence
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
export default {
    components: {
    },
    props: [],
    data: () => ({
        api_url: null,
        loading: true,
        sequence: null,
        clients: [],
        email_headers: [
            { text: "Details", sortable: false, width: "10%" },
            { text: "Content", sortable: false, width: "60%" },
        ],
    }),
    async mounted() {
        this.getActiveEmailSequence()
        this.getClients()
    },
    created() {
        this.api_url = process.env.VUE_APP_API_URL
    },
    computed: {
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        async getActiveEmailSequence() {
            await this.$axios.get('auth/fd_emails/get_active_email_sequence')
                .then(({ data }) => {
                    console.log(data)
                    this.loading = false
                    if (data.response) {
                        this.sequence = data.data
                    }
                })
        },
        async getClients() {
            await this.$axios.get('auth/fd_client/get_clients')
                .then(({ data }) => {
                    console.log(data)
                    if (data.response) {
                        this.clients = data.data
                    }
                })
        },
        async addClientsToSequence() {
            let payload = {
                name: this.sequence.name,
                client_ids: this.sequence.client_ids,
            }
            console.log(payload)
            await this.$axios.patch(`auth/fd_emails/edit_email_sequence/${this.sequence.id}`, payload)
                .then(({ data }) => {
                    console.log(data)
                })
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {},
};
</script>

<style scoped lang="scss">
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.placeholder {
    width: 30%;
    height: 20px;
    font-size: 12px;
    border-radius: 4px;
    padding-left: 10px;
}

.placeholder:hover {
    background-color: rgb(233, 233, 233);
}

::v-deep .placeholder input {
    cursor: pointer !important;
}

::v-deep .placeholder .v-input__slot,
::v-deep .placeholder .v-input__control {
    min-height: 10px !important;
    height: 20px !important;
}

::v-deep .placeholder .v-input__control>.v-input__slot:before,
::v-deep .placeholder .v-input__control>.v-input__slot:after {
    border-style: none !important;
}
</style>
