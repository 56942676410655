<template>
    <v-container fluid>
        <v-row justify="center">
            <v-dialog :value="value" persistent max-width="560">
                <v-card>
                    <v-card-title>
                        <small>Edit client</small>
                    </v-card-title>
                    <v-card-text>
                        <v-row class="mt-5">
                            <v-col :cols="isMobile() ? 12 : 6">
                                <strong> Firstname* </strong>
                                <v-text-field dense filled v-model="to_edit.firstname">
                                </v-text-field>
                                
                                <strong> Lastname* </strong>
                                <v-text-field dense filled v-model="to_edit.lastname"> </v-text-field>
                                
                                <strong> Mobile </strong>
                                <v-text-field dense filled type="number" v-model="to_edit.mobile">
                                </v-text-field>
                                
                                <strong> Birthday </strong>
                                <v-dialog max-width="300px" persistent v-model="birthday_modal">
                                    <template v-slot:activator="{ on: { click } }">
                                        <v-text-field dense filled v-on:click="click" v-model="to_edit.birthday"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="to_edit.birthday" scrollable actions>
                                        <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary" @click="birthday_modal = false">Cancel</v-btn>
                                        <v-btn color="primary" @click="birthday_modal = false">OK</v-btn>
                                        </v-card-actions>
                                    </v-date-picker>
                                </v-dialog>
                                
                                <strong> Age </strong>
                                <v-text-field dense filled v-model="to_edit.age">
                                </v-text-field>
                                
                                <strong> Gender </strong>
                                <v-select
                                    :items="genders"
                                    dense
                                    filled
                                    item-text="name"
                                    item-value="id"
                                    v-model="to_edit.gender_id"
                                >
                                </v-select>
                            </v-col>
                            <v-col :cols="isMobile() ? 12 : 6">
                                <strong> Address </strong>
                                <v-text-field dense filled v-model="to_edit.address">
                                </v-text-field>
                                
                                <strong> Country </strong>
                                <v-text-field dense filled v-model="to_edit.country"> </v-text-field>
                                
                                <strong> Town </strong>
                                <v-text-field dense filled v-model="to_edit.town">
                                </v-text-field>
                                
                                <strong> County </strong>
                                <v-text-field dense filled v-model="to_edit.county">
                                </v-text-field>
                                
                                <strong> Eircode </strong>
                                <v-text-field dense filled v-model="to_edit.eircode">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="cancel_edit_client()">
                            Cancel
                        </v-btn>
                        <v-btn color="#172C54" dark class="pl-10 pr-10" @click="edit_client()">
                            Edit
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>
<script>
export default {
    components: {},
    props: [
        'value',
        'client',
    ],
    data: () => ({
        edit_client_state: false,
        birthday_modal: false,
        genders: [
            {
                name: "Male",
                id: 1
            },
            {
                name: "Female",
                id: 2
            },
            {
                name: "Non-binary",
                id: 3
            },
        ],
        to_edit: {
            firstname: '',
            lastname: '',
            email: '',
            mobile: '',
            address: '',
            country: '',
            town: '',
            county: '',
            eircode: '',
            birthday: '',
            age: '',
            gender_id: '',
        },
    }),
    async mounted() {
        this.to_edit = {
            id: this.client.id,
            firstname: this.client.firstname,
            lastname: this.client.lastname,
            email: this.client.email,
            mobile: this.client.mobile,
            address: this.client.address,
            country: this.client.country,
            town: this.client.town,
            county: this.client.county,
            eircode: this.client.eircode,
            birthday: this.client.birthday,
            age: this.client.age,
            gender_id: this.client.gender_id,
        }
    },
    created() { },
    computed: {
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit(
                "auth/setMessage",
                { show: true, message: message },
                { root: 1 }
            );
        },
        cancel_edit_client() {
            this.$emit('cancel_dialog_edit_client')
        },
        async edit_client() {
            await this.$axios.patch("/auth/fd_client/edit_client_user", this.to_edit)
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                    if (data.response) {
                        this.$store.dispatch("fd_client/set_client", data.data);
                        this.$emit('cancel_dialog_edit_client')
                    }
                })
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {},
};
</script>

<style scoped lang="scss">
</style>
